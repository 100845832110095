/**
 * Hide the internal and/or external navbar. If no options are provided, hide the entire navbar div
 * 
 * @param {boolean} hide_internal whether to hide internal navbar
 * @param {boolean} hide_external whether to hide external navbar
 */
export function hide_navbar(hide_internal = false, hide_external = false) {
    if (hide_internal) {
        document.getElementById("internal-navbar").classList.add("hidden");
    }
    if (hide_external) {
        document.getElementById("external-navbar").classList.add("hidden");
    }
    // If no options are provided, hide entire navbar
    if (!hide_internal && !hide_external) {
        document.getElementById("navbar").classList.add("hidden");
    }
}

/**
 * Un-hide the internal and/or external navbar. If no options are provided, unhide the entire navbar div
 * 
 * @param {boolean} show_internal whether to show internal navbar
 * @param {boolean} show_external whether to show external navbar
 */
export function show_navbar(show_internal = false, show_external = false) {
    if (show_internal) {
        document.getElementById("internal-navbar").classList.remove("hidden");
    }
    if (show_external) {
        document.getElementById("external-navbar").classList.remove("hidden");
    }
    // If no options are provided, show entire navbar
    if (!show_internal && !show_external) {
        document.getElementById("navbar").classList.remove("hidden");
    }
}

/**
 * Try and get a key from the route params, else return a default value
 * 
 * @param {*} component Vue component
 * @param {string} key key to get from route params
 * @param {*} default_value default value to return if key is not found
 * @returns {*} value of key from route params, or default value if key is not found
 */
export function get_key_from_route_params(component, key, default_value = null) {
    return component.$route.params[key] ?? default_value;
}

/**
 * Try and get a key from the route query params, else return a default value
 * 
 * @param {*} component Vue component
 * @param {string} key key to get from route params
 * @param {*} default_value default value to return if key is not found
 * @returns {*} value of key from route params, or default value if key is not found
 */
export function get_key_from_query_params(component, key, default_value = null) {
    return component.$route.query[key] ?? default_value;
}

/**
 * Parse a boolean value from a string or boolean
 * 
 * @param {string | boolean} val value to parse
 * @returns {boolean} parsed boolean value
 */
export function parseBool(val) {
    if (val === true || val === "true") {
        return true;
    }
    return false;
}

/**
 * Navigate to a route.
 * 
 * @param {*} page "this" of the active component 
 * @param {string} name Name of the route to go to
 * @param {string} analytic_id analytic_id of the subroute to navigate to, if needed
 * @param {Object} query key-value pairs of query params, if needed
 * @param {boolean} new_tab set to true to open the route in a new tab
 */
export function navigateToRoute(page, name, analytic_id = null, query = {}, new_tab = false) {
    let params = {};
    if (analytic_id !== null) {
        params = { 
            analytic_id: analytic_id
        }
    }

    let route_params = { 
        name: name,
        params: params,
        query: query
    }

    if (page.$route.name !== name) {
        if (new_tab) {
            const route_data = page.$router.resolve(route_params)
            window.open(route_data.href, '_blank');
        }
        else {
            page.$router.push(route_params);
        }
    } 
}
