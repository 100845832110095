/* eslint-disable */
import Vue from "vue"
import Router from "vue-router"
import Login from "../components/Login"
import Home from "../components/Home"
import ErrorComponent from "../components/Error";
import UploadComponent from "../components/Upload";
import PlantCountQA from "../components/PlantCountQA";
import RowQA from "../components/RowQA";
import Progress from "../components/Progress";
import AnalyticsList from "../components/AnalyticsList";
import AnalyticDetails from "../components/AnalyticDetails.vue";

import { EXTERNAL_USER_ALLOWED_ROUTE_NAMES, RouteNames } from "../utils/constants";
import { isInternalUser } from "../utils/cognito";

Vue.use(Router)

const router = new Router({
    mode: "history",
    base: "/",
    routes: [
        {
            path: "/login",
            name: RouteNames.Login,
            component: Login,
        },
        {
            path: "/",
            name: RouteNames.Home,
            component: Home,
        },
        {
            path: "/error", 
            component: ErrorComponent,
        },
        {
            path: "/upload",
            name: RouteNames.Upload,
            component: UploadComponent,
        },
        {
            path: "/analytics/:analytic_id/plant_count_qa",
            name: RouteNames.PlantCountQA,
            component: PlantCountQA,
        },
        {
            // Alternative path for External Users
            path: "/plant_count_qa",
            name: RouteNames.ExternalPlantCountQA,
            component: PlantCountQA,
        },
        {
            path: "/analytics/:analytic_id/row_qa", 
            name: RouteNames.RowQA,
            component: RowQA,
        },
        {
            path: "/analytics/:analytic_id/gender_qa", 
            name: RouteNames.GenderQA,
            component: RowQA,
        },
        {
            path: "/analytics/:analytic_id/progress", 
            name: RouteNames.Progress,
            component: Progress,
        },
        {
            path: "/analytics",
            name: RouteNames.AnalyticsList,
            component: AnalyticsList,
        },
        {
            path: "/analytics/:analytic_id/details",
            component: AnalyticDetails,
            name: RouteNames.AnalyticDetails,
        },
        {
            path: "/analytics/:analytic_id",
            redirect: to => {
            // Redirects to /analytics/:analytic_id/details
            const { analytic_id } = to.params;
            return `/analytics/${analytic_id}/details`;
            }
        },

    ],
})

// Navigation guard to prevent unauthorized access to pages
router.beforeEach((to, from, next) => {
    if (to.name !== "Login" && !localStorage.getItem("user")) {
        // Redirect to login page if user is not logged in
        next({ name: "Login" })
    } else if (
        localStorage.getItem("user") && 
        !isInternalUser() &&
        !EXTERNAL_USER_ALLOWED_ROUTE_NAMES.includes(to.name) 
    ) {
        // Prevent navigation to any page other than PPA QA if user is not Internal
        alert("You are not authorized to access this page.");
        next({ name: "Home" });
    } 
    else next()
})

export default router
