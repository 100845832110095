<template>
    <div class="RowQA">
        <div id="image-info" class="header-info-container">
            <h2
                :title="analytic.field_name + ' / ' + image.name"
                style="flex: 7;"
            >{{ trimText(analytic.field_name) }} / {{ trimText(image.name) }}</h2>
            <h2>{{ gender_qa ? "Gender QA" : "Row QA" }}</h2>
        </div>
        <div id="container" class="ulabel-container"/>
    </div>
</template>
<script>
import { 
    gendered_row_qa_subtask,
    get_config_data, 
    get_slider_values_from_ulabel_html,
    ppa_qa_subtask,
    set_slider_values_in_ulabel_html,
    subimage_bounds_subtask 
} from "../utils/ulabel_utils.js";
import { getMeta } from "../utils/qa_utils.js";
import { RouteNames, ULABEL_SUBTASK_IDS } from "../utils/constants.js";
import { ULabel } from "ulabel"
import { extend_line, extend_all }  from "../utils/ulabel_extend_line";
import { get_username } from "../utils/qa_utils.js";
import { make_all_subimage_bboxes } from "../utils/ulabel_anno_utils.js";
import { hide_navbar, show_navbar, get_key_from_route_params, get_key_from_query_params, parseBool, navigateToRoute } from "../utils/nav_utils";
import { trimText } from "../utils/qa_utils";
import { getAnalytic, getNextImage, getImage } from "../utils/api_endpoints.js";
import { lockImage, unlockImage, saveAnnotations, updateQAStatus } from "../utils/api_endpoints.js";

export default {
    data: function () {
        return {
            analytic: {field_name: null},
            image: {name: null},
            n_subimages: 9,
            ulabel: null,
            rgb_displayed: true,
            gender_qa: false,
            opened_as_new_tab: false
        };
    },
    async mounted() {
        // Hide the entire navbar to force using the buttons
        hide_navbar();

        let analytic_id = get_key_from_route_params(this, "analytic_id");
        this.analytic = await getAnalytic(analytic_id);

        let image_id = get_key_from_query_params(this, "image_id");
        this.gender_qa = this.$route.path.includes("gender_qa");
        this.opened_as_new_tab = parseBool(get_key_from_query_params(this, "new_tab"));

        let next_image_found = false;
        if (image_id !== null) {
            this.image = await getImage(image_id, false, true);
            if (!await lockImage(this.image.id)) {
                alert("Image is locked by another user");
                this.navigate_away();
                return;
            }
            next_image_found = true;
        }

        if (!next_image_found){  
            // If no image_id is in the route params, we get the next available
            next_image_found = await this.load_next_image();
        }
        
        if (next_image_found) {
            // Add event listeners
            document.addEventListener("keydown", this.keydownEventHandler);
            await this.load_and_run_all();
        }
    },
    methods: {
        trimText,
        keydownEventHandler(e) {
            extend_line(e, this.ulabel, ULABEL_SUBTASK_IDS.ROW_QA);
            extend_all(e, this.ulabel, ULABEL_SUBTASK_IDS.ROW_QA);
            if (e.key == "b") {
                if (this.rgb_displayed) {
                    this.ulabel.swap_frame_image(this.image.binary_url);
                } else {
                    this.ulabel.swap_frame_image(this.image.rgb_url);
                }
                this.rgb_displayed = !this.rgb_displayed;
            }
            if (e.key == "Enter") {
                document.getElementById("approve").click();
            }
        },
        beforeDestoryHandler() {
            // Remove event listeners (including ULabel's)
            document.removeEventListener("keydown", this.keydownEventHandler);
            if (this.ulabel) {
                this.ulabel.remove_listeners();
            }
            // Un-hide the navbar
            show_navbar();
        },
        navigate_away() {
            this.beforeDestoryHandler();
            
            if (this.opened_as_new_tab) {
                // Close new tab, no need to navigate
                return window.close();
            }

            navigateToRoute(this, RouteNames.AnalyticDetails, this.analytic.id.toString())
        },

        /**
         * Load the next image to QA
         * 
         * @returns {Promise<boolean>} Whether the next image was loaded successfully
         */
        async load_next_image() {
            this.image = await getNextImage(this.analytic.id, this.gender_qa ? "gender_qa" : "row_qa")

            if (this.image === null) {
                this.navigate_away();
                alert("No Images Left to QA")
                return false;
            }

            if (!await lockImage(this.image.id)) {
                alert("Image is locked by another user");
                this.navigate_away();
                return false;
            }
            return true;
        },
        async load_and_run_all(reload_ulabel = false) {
            if (reload_ulabel) {
                // If ULabel is already running, just reload with new data
                await this.reload_ulabel();
            } else {
                // Start ULabel
                this.start_ULabel();
            }
        },
        async reload_ulabel() {
            // Set new image
            // Hack to load rgb and annotations at the same time
            await getMeta(this.image.rgb_url);
            this.ulabel.swap_frame_image(this.image.rgb_url);
            this.rgb_displayed = true;
            // Set new annotations
            this.ulabel.set_annotations(this.image.keypoint_annotations, ULABEL_SUBTASK_IDS.PLANT_COUNT_QA);
            this.ulabel.set_annotations(this.image.row_annotations, ULABEL_SUBTASK_IDS.ROW_QA);
            this.ulabel.set_annotations(this.getSubimageBoundsSubtask()["resume_from"], ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS);
            set_slider_values_in_ulabel_html(this.image, this.analytic);
        },
        async on_submit(annotations) {
            let slider_values = get_slider_values_from_ulabel_html();

            let rows = {
                "row_annotations": annotations["annotations"][ULABEL_SUBTASK_IDS.ROW_QA]
            }
            let keypoints = null;
            if (!this.analytic.rowfill) {
                keypoints = {
                    annotations: {
                        main: [annotations["annotations"][ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]],
                    },
                }
            }
            await saveAnnotations(
                this.image.id, 
                slider_values.keypoint_confidence_slider_val, 
                slider_values.row_dist_slider_val,
                keypoints,
                rows,
            );
            await updateQAStatus(this.image.id, this.gender_qa ? "gender_qa_status" : "row_qa_status", "completed");

            // If everything occurred as expected, unlock 
            await unlockImage(this.image.id)

            if (this.opened_as_new_tab) {
                // Leave QA cycle
                return this.navigate_away();
            }
            
            // Continue to next image
            if (await this.load_next_image()) {
                await this.load_and_run_all(true);
            }
        },
        async exitWithoutSaving() {
            // Unlock image and navigate away
            await unlockImage(this.image.id);
            this.navigate_away();
        },
        async rejectAndContinue() {
            await updateQAStatus(this.image.id, this.gender_qa ? "gender_qa_status" : "row_qa_status", "rejected");

            // Unlock
            await unlockImage(this.image.id)

            // Navigate to next image
            if (await this.load_next_image()) {
                await this.load_and_run_all(true);
            }
        },
        getSubmitButtons() {
            let args = {
                "set_saved": true,
                "size_factor": 0.8,
                "row_number": 0,
            }
            return [
                {
                    "name": "Approve",
                    "hook": this.on_submit,
                    "color": "lightgreen",
                    ...args,
                },
                {
                    "name": "Reject",
                    "hook": this.rejectAndContinue,
                    "color": "pink",
                    ...args,
                },
                {
                    "name": "Exit",
                    "hook": this.exitWithoutSaving,
                    "color": "khaki",
                    ...args,
                },
            ];
        },
        getSubimageBoundsSubtask() {
            let subimage_bbox_annos = make_all_subimage_bboxes(
                this.image.width,
                this.image.height,
                this.image.subimages,
                this.analytic.image_buffer_pct,
                0.995, // resize_factor
            )
            return subimage_bounds_subtask(subimage_bbox_annos, {inactive_opacity: 0.5});
        },
        start_ULabel() {
            let subtasks = {
                [ULABEL_SUBTASK_IDS.ROW_QA]: gendered_row_qa_subtask(this.image.row_annotations),
                [ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]: ppa_qa_subtask(this.image.keypoint_annotations),
                [ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS]: this.getSubimageBoundsSubtask(),
            };

            var email = get_username();
            if (email === undefined) {
                email = "Unauthenticated Stand QA User"
            }
            
            // Initial ULabel configuration
            const config = get_config_data(
                "container",
                this.image.rgb_url,
                email,
                subtasks,
                this.getSubmitButtons(),
                this.image,
                this.analytic,
            );
            let ulabel = new ULabel(config);
            this.ulabel = ulabel;

            // Wait for ULabel instance to finish initialization
            ulabel.init(function () {
                Object.defineProperties(
                    ulabel.finish_annotation,
                    {
                        name:
                        {
                            value: "finish_annotation",
                            writable: true,
                        }
                    }
                );
            });
        },
    },
};
</script>
