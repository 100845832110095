// Utils to load and unload Vuetify CSS in the browser

let vuetifyCSSLink;

export function loadVuetifyCSS() {
    if (!vuetifyCSSLink) {
        vuetifyCSSLink = document.createElement('link');
        vuetifyCSSLink.rel = 'stylesheet';
        // Use the local Vuetify CSS file   
        vuetifyCSSLink.href = '/css/vuetify.min.css';
        document.head.appendChild(vuetifyCSSLink);
    }
}

export function unloadVuetifyCSS() {
    if (vuetifyCSSLink) {
        document.head.removeChild(vuetifyCSSLink);
        vuetifyCSSLink = null;
    }
}