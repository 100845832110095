import { get_most_recent_action } from "./ulabel_utils.js";

function arr_equal (a1, a2) {
    var i = a1.length;
    while (i--) {
        if (a1[i] !== a2[i]) return false;
    }
    return true
}

function clip_point (point, width, height) {
    let clip_val = (val, min, max) => {
        if (val < min) {
            return min
        }
        if (val > max) {
            return max 
        }
        return val 
    }
    point[0] = clip_val(point[0], 0, width)
    point[1] = clip_val(point[1], 0, height)
    return point 
}

function walk_point (point, width, height, x_diff, y_diff) {
    while( // TODO calculate directly instead of iterating 
        arr_equal(
            clip_point(JSON.parse(JSON.stringify(point)), width, height),
            point
        )
    ) {
        point[0] += x_diff 
        point[1] += y_diff
    }
    return point 
}

function slope (p1, p2) {
    return (p2[1] - p1[1]) / (p2[0] - p1[0])
}

export function extend_line(event, ulabel, subtask_name) {
    if (event.key == "e") {
        let most_recent_action = get_most_recent_action(ulabel, subtask_name);
        if (most_recent_action != null) {
            if (most_recent_action["act_type"] == "finish_annotation" || most_recent_action["act_type"] == "edit_annotation" || most_recent_action["act_type"] == "move_annotation" || most_recent_action["act_type"] == "bypass") {
                var act_id = JSON.parse(most_recent_action["redo_payload"])["actid"]
                var annotation = ulabel.subtasks[subtask_name]["annotations"]["access"][act_id]
                if (annotation["spatial_type"] == "polyline") {
                    var width = ulabel.config["image_width"]
                    var height = ulabel.config["image_height"]

                    var points = annotation["spatial_payload"]
                    var n_points = points.length

                    // Extend leading edge
                    var p_extend = points[0] 
                    var p_anchor = points[1]
                    var leading_slope = slope(p_extend, p_anchor)

                    var walk = p_extend[0] > p_anchor[0] ? 1 : -1 
                    
                    var new_p_extend = clip_point(
                        walk_point(p_extend, width, height, walk, walk * leading_slope),
                        width, 
                        height 
                    )
                    annotation.spatial_payload[0] = new_p_extend

                    // Extend trailing edge 
                    p_extend = points[n_points - 1] 
                    p_anchor = points[n_points - 2] 
                    leading_slope = slope(p_extend, p_anchor)

                    walk = p_extend[0] > p_anchor[0] ? 1 : -1 
                    
                    new_p_extend = clip_point(
                        walk_point(p_extend, width, height, walk, walk * leading_slope),
                        width, 
                        height 
                    )
                    annotation.spatial_payload[n_points - 1] = new_p_extend


                    ulabel.rebuild_containing_box(act_id)
                    ulabel.redraw_annotation(act_id);
                }
            }
        }

    }

}

export function extend_all(event, ulabel, subtask_name) {
    if (event.key == "E") {
        let anno_ids = Object.keys(ulabel.subtasks[subtask_name]["annotations"]["access"]);
        let rebuild_ids = []
        for (var i = 0; i < anno_ids.length; i++) {
            let anno_id = anno_ids[i]
            let annotation = ulabel.subtasks[subtask_name]["annotations"]["access"][anno_id]
            if (annotation["spatial_type"] == "polyline") {
                var width = ulabel.config["image_width"]
                var height = ulabel.config["image_height"]
    
                var points = annotation["spatial_payload"]
                if(points.length < 2) {
                    continue
                }
                var n_points = points.length
    
                // Extend leading edge
                var p_extend = points[0] 
                var p_anchor = points[1]
                var leading_slope = slope(p_extend, p_anchor)
    
                var walk = p_extend[0] > p_anchor[0] ? 1 : -1 
                
                var new_p_extend = clip_point(
                    walk_point(p_extend, width, height, walk, walk * leading_slope),
                    width, 
                    height 
                )
                annotation.spatial_payload[0] = new_p_extend
    
                // Extend trailing edge 
                p_extend = points[n_points - 1] 
                p_anchor = points[n_points - 2] 
                leading_slope = slope(p_extend, p_anchor)
    
                walk = p_extend[0] > p_anchor[0] ? 1 : -1 
                
                new_p_extend = clip_point(
                    walk_point(p_extend, width, height, walk, walk * leading_slope),
                    width, 
                    height 
                )
                annotation.spatial_payload[n_points - 1] = new_p_extend
    
    
                ulabel.rebuild_containing_box(anno_id);
                rebuild_ids.push(anno_id);
            }
        }
        ulabel.redraw_multiple_spatial_annotations(rebuild_ids);
    }
}