// Utility functions for QA jobs
import { DEFAULT_USER } from "./constants.js";

/**
 * Util to truncate a full email address down to
 * the first name and last name first initial
 * 
 * @param {String} user full username (email address)
 * @returns {String} truncated username, ie trevor.b
 */
export function truncate_username(user) {
    return user.replace(/(.*)\.(.).+@.+/, "$1.$2");
}

export function get_username() {
    return localStorage.getItem("user") ?? DEFAULT_USER;
}

export function getMeta(url) {
    return new Promise((res, rej) => {
        let img = new Image();
        img.onload = () => res(img);
        img.onerror = () => rej();
        img.src = url;
    });
}

/**
 * Trims the text to a certain length and adds '...' at the end
 * 
 * @param {string} text Text to trim
 * @param {number} max_length Maximum number of characters to display
 * @returns {string} Trimmed text
 */
export function trimText(text = null, max_length = 40) {
    if (text === null) {
      return "";
    }
    if (text.length > max_length) {
      return text.slice(0, max_length) + '...';
    }
    return text;
  }
