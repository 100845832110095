<template>
    <div class="sidebar">
        <div class="sidebar-header">
            <span>Analytic ID: {{ analytic_id }}</span>
            <hr>
        </div>
        <div class="sidebar-content">
            <ul>
            <li>
                <div 
                @click="navigateTo(ComponentNames.AnalyticDetails)" 
                :class="{'active': isActiveRoute('details')}"
                >
                Details
                </div>
            </li>
            <li>
                <div 
                @click="navigateTo(ComponentNames.Progress)" 
                :class="{'active': isActiveRoute('progress')}"
                >
                Progress
                </div>
            </li>
            <hr>
            <li>
                <div @click="navigateTo(ComponentNames.RowQA)">
                Row QA
                </div>
            </li>
            <li>
                <div @click.prevent="!rowfill && navigateTo(ComponentNames.PlantCountQA)" :class="{ 'disabled': rowfill }">
                Plant Count QA
                </div>
            </li>
            <li>
                <div @click="navigateTo(ComponentNames.GenderQA)">
                Gender QA
                </div>
            </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { RouteNames } from "../utils/constants";
import { navigateToRoute } from "../utils/nav_utils";

export default {
    props: {
        analytic_id: {
            type: String,
            required: true
        }, 
        rowfill: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            ComponentNames: RouteNames,
        }
    },
    methods: {
        navigateTo(name) {
            navigateToRoute(this, name, this.analytic_id)
        },
        isActiveRoute(page) {
            // Check if the current route matches the page route
            return this.$route.path.includes(page);
        }
    }
}
</script>

<style scoped>
.sidebar {
    width: 200px;
    padding: 20px;
    margin-left: 20px;
    flex-shrink: 0;
    height: 100vh;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    left: 0;
    overflow-y: auto;
    top: 95px; 
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li > div {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    color: #333;
    font-weight: 500;
    font-size: 16px;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

.sidebar li > div.active {
    background-color: #e0e0e0;
}

.sidebar li > div:hover:not(.disabled) {
    background-color: #c8c8c8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Sidebar header (Analytic ID) */
.sidebar-header {
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
    text-align: center;
}

.sidebar li > div.disabled {
    color: gray;
    cursor: not-allowed;
}
</style>
