<template>
    <div class="upload">
        <h2>Upload Analytic</h2>
        <input v-model="s3Url" type="text" placeholder="JSON S3 URL" />
        <button @click="sendS3Url">Submit</button>
    </div>
</template>

<script>
import { uploadAnalytic } from '../utils/api_endpoints';

export default {
    data: function () {
        return {
            s3Url: ''
        };
    },

    methods: {
        async sendS3Url(){
            await uploadAnalytic(this.s3Url);
        }
    },
};
</script>

<style scoped>
.upload {
    margin-top: 95px;
}

</style>
