// Commonly used constants within the frontend application.

export const ULABEL_SUBTASK_IDS = { 
    PLANT_COUNT_QA: "plant_counting",
    ROW_QA: "row_classification",
    SUBIMAGE_BOUNDS: "subimage_bounds",
}

export const ULABEL_SUBTASK_DISPLAY_NAMES = {
    [ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]: "Plant Counting",
    [ULABEL_SUBTASK_IDS.ROW_QA]: "Row Classification",
    [ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS]: "Subimage Bounds",
}

export const ULABEL_INITIAL_LINE_SIZE = 2;

// ULabel toolbox items
export const ULABEL_TOOLBOX_ITEMS = {
    ModeSelect: 0,
    ZoomPan: 1,
    AnnotationResize: 2,
    AnnotationID: 3,
    RecolorActive: 4,
    ClassCounter: 5,
    KeypointSlider: 6,
    SubmitButtons: 7,
    FilterDistance: 8,
    Brush: 9,
}

export const DEFAULT_KEYPOINT_CONFIDENCE_SLIDER_VALUE = 30;

export const DEFAULT_ROW_DIST_SLIDER_VAL = 50;

// Login Page Modes
export const LOGIN_MODES = {
    SIGN_IN: "Sign In",
    SIGN_UP: "Sign Up",
    RESET_PASSWORD: "Reset Password",
}

// User Groups
export const USER_GROUPS = {
    INTERNAL: "Internal",
    EXTERNAL: "External",
}

export const DEFAULT_USER = "Stand QA User";

export const EXTERNAL_USER_ALLOWED_ROUTE_NAMES = [
    "Login",
    "Home",
    "ExternalPlantCountQA",
]

export class RouteNames {
    static Login = "Login";
    static Home = "Home";
    static Upload = "Upload";
    static PlantCountQA = "PPA QA";
    // Alias for PlantCountQA that skips analytic id
    static ExternalPlantCountQA = "ExternalPlantCountQA"; 
    static RowQA = "Row QA";
    // Alias for RowQA where gender_qa=true
    static GenderQA = "Gender QA"; 
    static Progress = "Progress";
    static AnalyticsList = "AnalyticsList";
    static AnalyticDetails = "AnalyticDetails";
}
