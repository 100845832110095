import { api_consolidator_error_handler } from "./api_utils";

/**
 * Lock image.
 * 
 * @param {int} image_id Image to lock
 * @returns {Promise<boolean>} Boolean indicating if lock was successful
 */
export async function lockImage(image_id) {
    try {
        await api_consolidator_error_handler({method: "POST", endpoint: `images/${image_id}/lock`, raise_error: true});
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * Unlock image.
 * 
 * @param {int} image_id Image to unlock
 * @returns {Promise<void>}
 */
export async function unlockImage(image_id) {
    await api_consolidator_error_handler({method: "POST", endpoint: `images/${image_id}/unlock`})
}

/**
 * Get next image marked for outsourcing
 * 
 * @returns {Promise<object>} Image object to process
 */
export async function getNextOutsourcedImage() {
    return await api_consolidator_error_handler({method: "GET", endpoint: `images/outsourced/next`})
}

/**
 * Save annotations for an image.
 * 
 * @param {int} image_id Image to save annotations for
 * @param {int} keypoint_slider Keypoint slider value when image was saved
 * @param {int} row_distance_slider Row distance slider value when image was saved
 * @param {object} keypoint_annotations Json blob with updated keypoint annotations
 * @param {object} row_annotations Json blob with updated row annotations
 * @returns {Promise<void>}
 */
export async function saveAnnotations(image_id, keypoint_slider, row_distance_slider, keypoint_annotations=null, row_annotations=null) {
    let params = {
        keypoint_slider: keypoint_slider,
        row_distance_slider: row_distance_slider,
    }

    let data = {
        keypoints: keypoint_annotations,
        rows: row_annotations,
    }

    await api_consolidator_error_handler({method: "PATCH", endpoint: `images/${image_id}/annotations`, data: data, params: params});
}

/**
 * Update qa status for an image
 * 
 * @param {int} image_id Image to update
 * @param {string} qa_type One of [ppa_qa_status, row_qa_status, gender_qa_status]
 * @param {string} status One of [rejected, not_done, completed]
 * @returns {Promise<void>}
 */
export async function updateQAStatus(image_id, qa_type, status) {
    let data = {}
    if (qa_type === "ppa_qa_status") {
        data["ppa_qa_status"] = status
    } else if (qa_type === "row_qa_status") {
        data["row_qa_status"] = status
    } else if (qa_type === "gender_qa_status") {
        data["gender_qa_status"] = status
    } else {
        console.log("Invalid QA type")
    }
    await api_consolidator_error_handler({method: "PATCH", endpoint: `images/${image_id}`, data: data});
}

/**
 * Get image by id.
 * 
 * @param {int} image_id Image to retrieve
 * @param {boolean} presigned_thumbnails Set to true to include thumbnail pre-signed urls in the returned image object
 * @param {boolean} presigned_urls Set to true to include full-size pre-signed urls (and annotations) in the returned image object
 * @returns {Promise<object>} Image object
 */
export async function getImage(image_id, presigned_thumbnails = false, presigned_urls = false) {
    let params = {
        presigned_thumbnails: presigned_thumbnails,
        presigned_urls: presigned_urls
    }
    return await api_consolidator_error_handler({method: "GET", endpoint: `images/${image_id}`, params: params})
}

/**
 * Get all analytics.
 * 
 * @returns {Promise<object[]>} List of analytic objects
 */
export async function getAnalytics() {
    return await api_consolidator_error_handler({method: "GET", endpoint: "analytics", paginated: true})
}

/**
 * Update analytic attributes.
 * 
 * @param {int} analytic_id Analytic to update
 * @param {boolean} outsourced Whether to send the analytic to outsourcing or not
 * @param {int} keypoint_slider_default Default keypoint slider value for the analytic
 * @param {int} row_distance_slider_default Default row distance slider value for the analytic
 * @param {int} image_buffer_pct Percentage to buffer all images from the edges
 * @param {string} status One of [ready_to_process, in_progress, completed, abandoned]
 * @param {int} priority Analytic priority for outsourcing workers
 * @param {boolean} rowfill If corn field should be processed in rowfill mode
 * @returns {Promise<void>}
 */
export async function patchAnalytic(
    analytic_id, 
    outsourced = null, 
    keypoint_slider_default = null, 
    row_distance_slider_default = null, 
    image_buffer_pct = null, 
    status = null,
    priority = null,
    rowfill = null,
) {
    let data = {
        outsourced: outsourced,
        keypoint_slider_default: keypoint_slider_default,
        row_distance_slider_default: row_distance_slider_default,
        image_buffer_pct: image_buffer_pct,
        status: status,
        priority: priority,
        rowfill: rowfill
    }

    await api_consolidator_error_handler({method: "PATCH", endpoint: `analytics/${analytic_id}`, data: data})
}

/**
 * Get analytic by id.
 * 
 * @param {int} analytic_id Analytic to retrieve
 * @returns {Promise<object>} Analytic object
 */
export async function getAnalytic(analytic_id) {
    try {
        return await api_consolidator_error_handler({method: "GET", endpoint: `analytics/${analytic_id}`, raise_error: true})
    } catch (error) {
        let error_msg = error.message;
        if (error.response) {
            error_msg = error.response.data.detail.msg;
        }
        alert(`Error getting analytic id=${analytic_id}: ${error_msg}`);
    }
}

/**
 * Retrieve analytic processing parameter json.
 * 
 * @param {int} analytic_id Analytic to get json for
 * @returns {Promise<object>} Object containing analytic processing parameters
 */
export async function getAnalyticJson(analytic_id) {
    return await api_consolidator_error_handler({method: "GET", endpoint: `analytics/${analytic_id}/json`})
}

/**
 * Generate and upserted feature set for the given analytic.
 * 
 * @param {int} analytic_id Analytic to export
 * @param {boolean} upload_as_released Whether the upserted feature set should be released/unreleased
 * @param {boolean} include_rejected Whether to include the rejected images in the output feature set
 * @returns {Promise<void>}
 */
export async function exportAnalytic(analytic_id, upload_as_released, include_rejected) {
    let params = {
        upload_as_released: upload_as_released,
        include_rejected: include_rejected
    }

    try {
        await api_consolidator_error_handler(
            {method: "POST", endpoint: `analytics/${analytic_id}/deliverables`, params: params, raise_error: true}
        );
        alert("Export Started");
    } catch (error) {
        if (error.response) {
            alert("Failed to start export: " + error.response.data.detail);
            console.error(error.response);
        } else {
            alert("Failed to start export: " + error.message);
            console.error(error.message);
        }
    }
} 

/**
 * Upload new analytic to the tool.
 * 
 * @param {string} json_s3_path S3 path to the analytic parameter json file detailing the analytic to upload
 * @returns {Promise<void>}
 */
export async function uploadAnalytic(json_s3_path) {
    try {
        await api_consolidator_error_handler(
            {method: "POST", endpoint: "analytics", params: {json_s3_path: json_s3_path}, raise_error: true}
        );
        alert("Uploading Analytic");
    } catch(error) {
        alert("Error Uploading Analytic");
        console.error(error);
    }
}

/**
 * Get next image ready for a given qa type for an analytic.
 * 
 * @param {int} analytic_id Analytic to get next image for
 * @param {string} qa_type One of [ppa_qa, row_qa, gender_qa]
 * @returns {Promise<object>} Image object to process
 */
export async function getNextImage(analytic_id, qa_type) {
    let params = {qa_type: qa_type}
    return await api_consolidator_error_handler({method: "GET", endpoint: `analytics/${analytic_id}/next_annotate_image`, params: params})
}

/**
 * Get all images belonging to a given analytic.
 * 
 * @param {int} analytic_id Analytic to get images for
 * @returns {Promise<object[]>} List of image objects
 */
export async function getImages(analytic_id) {
    return await api_consolidator_error_handler({method: "GET", endpoint: `analytics/${analytic_id}/images`, paginated: true})
}

/**
 * Update subimage rejected flag.
 * 
 * @param {int} image_id Image that contains the subimage
 * @param {int} subimage_idx Index of the subimage to update
 * @param {boolean} rejected Whether the subimage is rejected or not
 * @returns {Promise<void>}
 */
export async function patchSubimage(image_id, subimage_idx, rejected) {
    let params = {rejected: rejected}
    await api_consolidator_error_handler({method: 'PATCH', endpoint: `images/${image_id}/subimages/${subimage_idx}`, params: params})
}
