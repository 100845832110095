import Vue from 'vue'
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuetify from 'vuetify'
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuex);
Vue.use(Vuetify)

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
