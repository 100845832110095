<template>
    <div class="analytic-details">
        <Sidebar :analytic_id="analytic_id" :rowfill="rowfill_flag"/>
        <div class="details" :class="{disabled: is_refreshing}">
            <div class="base-grouping">
                <h3>Analytic Details</h3>
                <table class="details-table">
                    <tr>
                        <td class="label-column">Analytic ID:</td>
                        <td class="data-column">{{ analytic_id }}</td>
                    </tr>
                    <tr>
                        <td class="label-column">Organization Name:</td>
                        <td class="data-column">{{ org_name }}</td>
                    </tr>
                    <tr>
                        <td class="label-column">Field Name:</td>
                        <td class="data-column">{{ field_name }}</td>
                    </tr>
                    <tr>
                        <td class="label-column">Crop Type:</td>
                        <td class="data-column">{{ crop_type }}</td>
                    </tr>
                    <tr>
                        <td class="label-column">Run Type:</td>
                        <td class="data-column">{{ run_type }}</td>
                    </tr>
                    <tr>
                        <td class="label-column">Sentera ID:</td>
                        <td class="data-column">
                            <a :href="getActiveAdminURL()" target="_blank">{{ sentera_id }}</a>
                        </td>
                    </tr>
                    <tr>
                        <td class="label-column">Planting Pattern:</td>
                        <td class="data-column">{{ planting_pattern }}</td>
                    </tr>
                    <tr>
                        <td class="label-column">Created At:</td>
                        <td class="data-column">{{ created_at }}</td>
                    </tr>
                </table>
                <div class="sub-grouping" :style="{ backgroundColor: statusColor }">
                    <p class="input-label" style="font-weight:500">Status</p>
                    <select v-model="status" id="status-select">
                        <option value="ready_to_process">Not Started</option>
                        <option value="in_progress">In Progress</option>
                        <option value="completed">Completed</option>
                        <option value="abandoned">Abandoned</option>
                    </select>
                </div>
                <div class="sub-grouping">
                    <div class="checkbox-container">
                        <input 
                            type="checkbox" 
                            v-model="rowfill_flag" 
                            :disabled="crop_type !== 'corn'" 
                        />
                        <label class="input-label"> Rowfill </label>
                    </div>

                    <p class="input-label">Default Keypoint Confidence (%)</p>

                    <div class="slider-container">
                        <span class="slider-value">{{ confidence_slider }}</span>
                        <input type="range" v-model="confidence_slider" min="0" max="100" />
                    </div>

                    <p class="input-label">Default Row Distance (pixels)</p>

                    <div class="slider-container">
                        <span class="slider-value">{{ row_distance_slider }}</span>
                        <input type="range" v-model="row_distance_slider" min="0" max="300" />
                    </div>
                    <p class="input-label">Image Buffer (%)</p>

                    <div class="slider-container">
                        <span class="slider-value">{{ image_buffer }}</span>
                        <input type="range" v-model="image_buffer" min="0" max="100" />
                    </div>

                    <div class="checkbox-container" style="margin-top: 20px">
                        <input type="checkbox" v-model="outsourcing" />
                        <label class="input-label">Outsourcing</label>
                    </div>

                    <div class="priority-container">
                        <label class="input-label">Priority</label>
                        <input type="number" v-model="priority" min="1" max="100" />
                    </div>

                    <button @click="saveUpdates" style="margin-top: 30px">Save Updates</button>
                </div>
            </div>
            <div class="base-grouping">
                <h3 style="margin-bottom: 30px">Export</h3>
                <div class="sub-grouping">
                    <div class="checkbox-container">
                        <input type="checkbox" v-model="upload_as_released_flag" />
                        <label class="input-label"> Upload as Released </label>
                    </div>

                    <div class="checkbox-container">
                        <input type="checkbox" v-model="rejected_flag" />
                        <label class="input-label"> Include Rejected </label>
                    </div>

                    <button @click="runExport">Export</button>
                </div>
            </div>
            <div class="dropdown-container base-grouping">
                <div class="dropdown-header" @click="toggleDropdown">
                    <span>Full Analytic JSON</span>
                    <span :class="{'arrow-down': dropdown_is_open, 'arrow-right': !dropdown_is_open}">▼</span>
                </div>

                <div v-show="dropdown_is_open" class="dropdown-content">
                    <code style="text-align: left;">
                        <pre>{{JSON.stringify(field_json, null, 4)}}</pre>
                    </code>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    exportAnalytic,
    getAnalytic, 
    getAnalyticJson, 
    patchAnalytic,
} from '../utils/api_endpoints';
import { get_key_from_route_params } from "../utils/nav_utils";
import Sidebar from './Sidebar.vue'; 

export default {
    components: {
        Sidebar
    },
    data() {
        return {
            field_json: null,
            analytic_id: '',
            field_name: null,
            org_name: null,
            crop_type: null,
            run_type: null,
            sentera_id: null,
            status: null,
            confidence_slider: null,
            row_distance_slider: null,
            image_buffer: null,
            outsourcing: null,
            priority: null,
            rejected_flag: true,
            upload_as_released_flag: false,
            rowfill_flag: false,
            dropdown_is_open: false, 
            planting_pattern: null,
            created_at: null,
            is_refreshing: false,
        };
    },
    async mounted() {
        // Make API calls first before setting values so everything loads at the same time
        this.analytic_id = get_key_from_route_params(this, "analytic_id");
        const analytic_info = await getAnalytic(this.analytic_id);
        this.field_name = analytic_info.field_name
        this.org_name = analytic_info.organization_name;
        this.crop_type = analytic_info.crop_type;
        this.run_type = analytic_info.stand_type;
        this.sentera_id = analytic_info.sentera_id;
        this.status = analytic_info.status;

        const date = new Date(analytic_info.created_at);
        this.created_at = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });

        this.confidence_slider = analytic_info.keypoint_slider_default
        this.row_distance_slider = analytic_info.row_distance_slider_default
        this.image_buffer = analytic_info.image_buffer_pct
        this.outsourcing = analytic_info.outsourced
        this.priority = analytic_info.priority
        this.rowfill_flag = analytic_info.rowfill

        this.fetchFieldJson()
    },
    computed: {
        statusColor() {
            switch (this.status) {
                case 'completed':
                    return '#28a745'; // Green
                case 'abandoned':
                    return '#d2042d'; // Red 
                case 'in_progress':
                    return '#FFEB3B'; // Yellow
                default:
                    return '#f9f9f9'; // Default background color
            }
        }
    },
    methods: {
        async runExport() {
            await exportAnalytic(this.analytic_id, this.upload_as_released_flag, this.rejected_flag);
        },
        async saveUpdates() {
            if (this.rowfill_flag && this.outsourcing) {
                alert("Can't send rowfill fields to outsourcing");
                return;
            }

            if (this.outsourcing && this.status !== "in_progress") {
                if (!confirm("Analytic must be in progress to be viewable by outsourcing\nDo you wish to continue?")) {
                    return;
                }
            }

            this.is_refreshing = true;
            await patchAnalytic(
                this.analytic_id, 
                this.outsourcing, 
                this.confidence_slider, 
                this.row_distance_slider, 
                this.image_buffer, 
                this.status,
                this.priority,
                this.rowfill_flag,
            )
            this.is_refreshing = false;
        },
        toggleDropdown() {
            this.dropdown_is_open = !this.dropdown_is_open; // Toggle the dropdown state
        },
        getActiveAdminURL() {
            let env = process.env.VUE_APP_API_ENV === "prod" ? "" : process.env.VUE_APP_API_ENV;
            return `https://admin${env}.sentera.com/admin/search?sentera_id=${this.sentera_id}`;
        },
        async fetchFieldJson() {
            this.field_json = await getAnalyticJson(this.analytic_id);
            if (this.run_type == "male_female") {
                let male_row_count = this.field_json["order"]["settings"]["male_row_count"]
                let female_row_count = this.field_json["order"]["settings"]["female_row_count"]
                this.planting_pattern = `${female_row_count} : ${male_row_count}`
            } else {
                this.planting_pattern = "N/A"
            }
        }
    }
}
</script>

<style scoped>
/* Main Container */
.analytic-details {
    display: flex;
    margin-top: 95px;
    margin-left: 250px;
}

/* Details */
.details {
    flex: 1;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.details.disabled {
    opacity: 0.6;
    pointer-events: none;
}

/* General */
.base-grouping {
    padding: 20px;
    width: 800px;
    margin: auto;
    margin-bottom: 30px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sub-grouping {
    width: 600px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    margin: auto;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

h3 {
    margin-bottom: 20px;
}

/* Buttons */
button {
    padding: 10px 20px;
    font-size: 1.2em;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

/* Analytic Details Table */
.details-table {
    width: 600px;
    border-collapse: collapse;
    margin: auto;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #e0e0e0;
}

.details-table td {
    padding: 10px 15px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 14px;
}

.label-column {
    background-color: #f0f0f0;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
}

.data-column {
    text-align: left;
    width: 100%;
}

/* Status Styling */
#status-select {
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

#status-select:hover {
    border-color: #007bff;
}

#status-select:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Input Fields */
.input-label {
    font-size: 16px;
}

/* Slider Bars */
.slider-container {
    display: flex;
    align-items: center;
    margin: auto;
    width: 80%;
    margin-bottom: 10px;
}

.slider-value {
    width: 30px;
    text-align: left;
}

input[type="range"] {
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background: #dcdcdc;
}

/* Checkboxes */
.checkbox-container {
    margin: 10px;
}

input[type="checkbox"] {
    margin-right: 10px;
}

/* Priority Input */
.priority-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.priority-container label {
    margin-right: 10px;
}

input[type="number"] {
    width: 80px;
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
}

input[type="number"]:hover {
    border-color: #007bff;
}

input[type="number"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.3);
}

/* JSON Dropdown */
.dropdown-container {
    width: 800px;
    padding: 10px;
    text-align: center;
}

.dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.dropdown-content {
    margin-top: 10px;
    padding-left: 10px;
    border-left: 2px solid #ccc;
    text-align: left;
    max-height: 600px;
    overflow-y: auto;
}

.arrow-right {
    transform: rotate(90deg);
}

.arrow-down {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

</style>
