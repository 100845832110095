<template>
    <div id="navbar">
        <b-navbar toggleable="sm" type="light" variant="light">
            <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
            <b-navbar-brand href="/">
                <img src="../assets/logo.png" style="width:50px;height:50px">
                Stand QA Tool
            </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav>
                <!-- Navbar for Internal Users -->
                <b-navbar-nav id="internal-navbar" v-bind:class="internalNavbarClass()">
                    <b-nav-item @click="navigateTo(ComponentNames.Login)">Logout</b-nav-item>
                    <b-nav-item @click="navigateTo(ComponentNames.Upload)">Upload</b-nav-item>
                    <b-nav-item @click="navigateTo(ComponentNames.AnalyticsList)">Analytics</b-nav-item>
                </b-navbar-nav>
                <!-- Navbar for External Users -->
                <b-navbar-nav id="external-navbar" v-bind:class="externalNavbarClass()">
                    <b-nav-item @click="navigateTo(ComponentNames.Login)">Logout</b-nav-item>
                    <b-nav-item @click="navigateTo(ComponentNames.ExternalPlantCountQA)">Plant Count QA</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import { isInternalUser } from "../utils/cognito";
import { RouteNames } from "../utils/constants";
import { navigateToRoute } from "../utils/nav_utils";

export default {
    computed: {
        // Expose the ComponentNames object to the template
        ComponentNames() {
            return RouteNames;
        }
    },
    methods: {
        internalNavbarClass() {
            return isInternalUser() ? "" : "hidden";
        },
        externalNavbarClass() {
            return isInternalUser() ? "hidden" : "";
        },
        navigateTo(name) {
            navigateToRoute(this, name)
        },
    }
}
</script>

<style scoped>
p { 
    margin: 0;
    font-size: 13px; 
}

.hidden {
    display: none;
}

div.info {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

#navbar {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%; 
    z-index: 1000;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
