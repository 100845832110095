import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoRefreshToken } from "amazon-cognito-identity-js";
import axios from "axios";
import { USER_GROUPS } from "./constants";

const poolData = {
    UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID
};
export const UserPool = new CognitoUserPool(poolData);

/**
 * Register a user with Cognito
 * 
 * @param {string} email email address
 * @param {string} password password
 * @returns {Promise} Promise that resolves with the result of the registration
 */
export async function register (email, password) {
    return new Promise((resolve, reject) => {
        // TODO: Add user to default group
        UserPool.signUp(email, password, [], null, (err, result) => {
            if (err) {
                reject(err);
                return;
            }
            resolve(result);
        });
    });
}

/**
 * Confirm a user with Cognito by prompting the user for the confirmation code until it is successful
 * 
 * @param {string} email email address
 * @param {string} password password
 * @param {string} code confirmation code
 * @returns {Promise} Promise that resolves with the result of the confirmation
 */
export async function confirm (email, password, code) {
    const user = new CognitoUser({
        Username: email,
        Pool: UserPool
    });
    return new Promise((resolve) => {
        user.confirmRegistration(code, true, (err) => {
            if (err) {
                return resolve(false);
            }
            // Authenticate the user to get the token
            authenticate(email, password).then(() => {
                return resolve(true);
            });
        });
    });
}

/**
 * Authenticate a user with Cognito
 * 
 * @param {string} email email address
 * @param {string} password password
 * @returns {Promise} Promise that resolves with the result of the authentication
 */
export async function authenticate (email, password) {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        user.authenticateUser(authDetails,{
            onSuccess:(result) => {
                // Save the token for future API calls
                axios.defaults.headers.common.Authorization = `Bearer ${result.accessToken.jwtToken}`;
                // Store in local storage
                localStorage.setItem("auth", `Bearer ${result.accessToken.jwtToken}`);
                localStorage.setItem("refresh", result.refreshToken.token);
                localStorage.setItem("user", email);
                localStorage.setItem(
                    "user_groups",
                    // If no groups are returned, set to empty array
                    result.idToken.payload["cognito:groups"] === undefined 
                        ? JSON.stringify([])
                        : JSON.stringify(result.idToken.payload["cognito:groups"])
                );
                resolve(result);
            },
            onFailure:(err) => {
                reject(err);
            }
        });
    });
}

/**
 * Reset a user's password
 * 
 * @param {string} email email address
 * @param {string} new_password new password
 * @returns {Promise} Promise that resolves with the result of the password reset 
 */
export async function resetPassword (email, new_password) {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        });
        user.forgotPassword({
            onSuccess: (result) => {
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
            inputVerificationCode: function() {
                const verificationCode = prompt("Please input verification code sent to " + email + ": ");
                user.confirmPassword(verificationCode, new_password, this);
            }
        });
    });
}

/**
 * Log the user out
 * 
 */
export function logout () {
    let email = localStorage.getItem("user");

    if (email) {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        });
        user.signOut();
    }
    
    // Remove the token and user from local storage
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    localStorage.removeItem("refresh");
    localStorage.removeItem("user_groups");
}

/**
 * Get the expiration time of the user's token
 * 
 * @returns {number} Expiration time of the user's token
 */
export function getTokenExpiration() {
    const token = localStorage.getItem("auth");
    if (!token) {
        return null;
    }
    const parts = token.split(".");
    if (parts.length !== 3) {
        return null;
    }
    const payload = JSON.parse(atob(parts[1]));
    return payload.exp * 1000;
}

/**
 * Refresh the user's token
 * 
 */
export function refreshToken() {
    return new Promise((resolve, reject) => {
        const email = localStorage.getItem("user");
        const refreshToken = localStorage.getItem("refresh");

        if (!email || !refreshToken) {
            return reject(new Error("No user or refresh token found"));
        }

        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        });
        const RefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });
        user.refreshSession(RefreshToken, (err, session) => {
            if (err) {
                // Token refresh failure
                return reject(err);
            }

            // Save the new tokens
            axios.defaults.headers.common.Authorization = `Bearer ${session.accessToken.jwtToken}`;
            localStorage.setItem("auth", `Bearer ${session.accessToken.jwtToken}`);
            localStorage.setItem("refreshToken", session.refreshToken.token); // Update refresh token
            resolve(session);
        });
    });
}

/**
 * Wrapper for refreshToken that first checks if the token is expired.
 * If the token isn't present or can't be refreshed, the user redirected 
 * to the login page, where they are logged out.
 * 
 */
export async function refreshTokenIfExpired() {
    const expiration = getTokenExpiration();
    // Add a 5 minute buffer to the expiration time
    // 5 * 60 * 1000 = 300000
    if (!expiration || expiration < Date.now() + 300000) {
        try {
            await refreshToken();
        } catch (error) {
            // If the token can't be refreshed, log the user out
            console.error(error);
            goToLogin();
        }
    }
}

/**
 * Determine whether the user is logged in
 * 
 * @returns {boolean} True if the user is logged in, false otherwise
 */
export function isLoggedIn() {
    return localStorage.getItem("auth") !== null;
}

/**
 * Determine whether the user as the "Internal" group
 * 
 * @returns {boolean} True if the user is Internal, false otherwise
 */
export function isInternalUser() {
    let user_groups = JSON.parse(localStorage.getItem('user_groups')) || [];
    return user_groups.includes(USER_GROUPS.INTERNAL);
}

/**
 * Determine whether the user as the "External" group
 * 
 * @returns {boolean} True if the user is External, false otherwise
 */
export function isExternalUser() {
    let user_groups = JSON.parse(localStorage.getItem('user_groups')) || [];
    return user_groups.includes(USER_GROUPS.EXTERNAL);
}

/**
 * Go to the login page
 */
export function goToLogin() {
    location.href = "/login";
}